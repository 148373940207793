import { defineStore } from "pinia";
import { useSessionStore } from "@/stores/session";

export const useNavStore = defineStore("nav", {
  state: () => {
    return {
      channels: null,
      activeChannel: null,
      hideMenu: false,
    };
  },
  getters: {
    currentProgram() {
      const program = this.activeChannel?.programs[0];

      return {
        ...program,
        bannerImg: useThumbnailOrientation(program, {
          orientation: "HorizontalImage",
        }),
      };
    },

    parsedChannels() {
      return this.channels?.map((c) => {
        return {
          ...c,
          images: useMediaAssets(c),
        };
      });
    },
  },
  actions: {
    async fetchChannels() {
      if (this.channels?.length > 0) return;

      const res = await useGetMethod(
        "/api/biz/live/home/channels",
        {},
        { raw: true }
      );

      this.channels = res.response;
    },

    /**
     * check the profile store for any kids accounts
     * if there's no kids account, redirect to kids homepage
     *
     * if there's one kids account, switch to that kid profile and redirect to kids homepage
     *
     * if there's more than one kids account, switch to whos-watching
     */
    onKidsClick() {
      const localePath = useLocalePath();
      navigateTo({
        path: localePath("/kids"),
      });
    },

    /**
     * if there is one adult profile, switch to that profile
     * if there's more than one adult profile, redirect to whos-watching
     *
     * otherwise just redirect to adult profile
     */
    onExitKidsClick() {
      const localePath = useLocalePath();
      const { activeProfile } = useSessionStore();
      if ( activeProfile?.isKid ) {
        navigateTo({ 
          path: localePath("/profiles"),
          query: activeProfile.pin_code_exist ? { step: 'compareParental'}:{}
        })
      } else {
        navigateTo({
          path: localePath("/"),
        });
      }
    },
  },
  // persist: {
  //   storage: persistedState.sessionStorage,
  // },
});
